import React, { useState } from 'react';
import axios from 'axios';

function DocumentUpload({ projectId }) {
  const [file, setFile] = useState(null);
  const [type, setType] = useState('bank_statement');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) return;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('projectId', projectId);
    formData.append('type', type);

    try {
      await axios.post('/api/documents/upload', formData);
      alert('Документ завантажено');
    } catch (error) {
      console.error('Помилка завантаження документа:', error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>Завантажити документ</h3>
      <select value={type} onChange={(e) => setType(e.target.value)}>
        <option value="bank_statement">Банківська виписка</option>
        <option value="management_report">Управлінський звіт</option>
        <option value="invoice">Інвойс</option>
        {/* Додати інші типи документів */}
      </select><br />
      <input
        type="file"
        onChange={(e) => setFile(e.target.files[0])}
        required
      /><br />
      <button type="submit">Завантажити</button>
    </form>
  );
}

export default DocumentUpload;
