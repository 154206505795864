import React, { useState, useEffect } from 'react';
import axios from 'axios';

function DocumentView({ match }) {
  const documentId = match.params.id;
  const [document, setDocument] = useState(null);
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');

  // Завантаження документа та його нотаток
  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const res = await axios.get(`/api/documents/${documentId}`);
        setDocument(res.data);
      } catch (error) {
        console.error('Помилка завантаження документа:', error.message);
      }
    };

    const fetchNotes = async () => {
      try {
        const res = await axios.get(`/api/notes/document/${documentId}`);
        setNotes(res.data);
      } catch (error) {
        console.error('Помилка завантаження нотаток:', error.message);
      }
    };

    fetchDocument();
    fetchNotes();
  }, [documentId]);

  // Додавання нової нотатки
  const handleAddNote = async () => {
    if (!newNote) return;
    try {
      const res = await axios.post('/api/notes', {
        documentId,
        content: newNote,
      });
      setNotes([...notes, res.data]);
      setNewNote('');
    } catch (error) {
      console.error('Помилка додавання нотатки:', error.message);
    }
  };

  if (!document) return <div>Завантаження...</div>;

  return (
    <div>
      <h2>Деталі документа</h2>
      <p>Тип: {document.type}</p>
      <p>Завантажено: {new Date(document.uploadedAt).toLocaleString()}</p>
      <h3>Маркерована інформація</h3>
      <pre>{JSON.stringify(document.markers, null, 2)}</pre>
      <h3>Нотатки</h3>
      <ul>
        {notes.map((note) => (
          <li key={note._id}>
            <strong>{note.author.name}:</strong> {note.content}
          </li>
        ))}
      </ul>
      <input
        type="text"
        placeholder="Нова нотатка"
        value={newNote}
        onChange={(e) => setNewNote(e.target.value)}
      />
      <button onClick={handleAddNote}>Додати нотатку</button>
    </div>
  );
}

export default DocumentView;
