import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null); // Додаємо стан для помилки

  // Встановлення базового URL для axios
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

  const login = async (email, password) => {
    try {
      const res = await axios.post('/api/users/login', { email, password });
      localStorage.setItem('token', res.data.token);
      setUser(res.data);
      setError(null); // Очищення помилки при успішному вході
    } catch (error) {
      console.error('Помилка входу:', error.response?.data?.message || error.message);
      setError(error.response?.data?.message || 'Помилка входу'); // Встановлюємо помилку
    }
  };

  const register = async (name, email, password) => {
    try {
      const res = await axios.post('/api/users/register', { name, email, password });
      localStorage.setItem('token', res.data.token);
      setUser(res.data);
      setError(null); // Очищення помилки при успішній реєстрації
    } catch (error) {
      console.error('Помилка реєстрації:', error.response?.data?.message || error.message);
      setError(error.response?.data?.message || 'Помилка реєстрації'); // Встановлюємо помилку
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
    setError(null); // Очищення помилки при виході
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setUser({ token });
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, register, logout, error }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
