import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DocumentUpload from './DocumentUpload';
import { Link } from 'react-router-dom';

function ProjectDetail({ match }) {
  const projectId = match.params.id;
  const [project, setProject] = useState(null);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    // Отримання деталей проекту
    const fetchProject = async () => {
      try {
        const res = await axios.get(`/api/projects/${projectId}`);
        setProject(res.data);
      } catch (error) {
        console.error('Помилка завантаження проекту:', error.message);
      }
    };

    // Отримання документів проекту
    const fetchDocuments = async () => {
      try {
        const res = await axios.get(`/api/documents/project/${projectId}`);
        setDocuments(res.data);
      } catch (error) {
        console.error('Помилка завантаження документів:', error.message);
      }
    };

    fetchProject();
    fetchDocuments();
  }, [projectId]);

  if (!project) return <div>Завантаження...</div>;

  return (
    <div>
      <h2>{project.name}</h2>
      <p>{project.description}</p>
      <p>
        Дати аудиту: {new Date(project.startDate).toLocaleDateString()} —{' '}
        {new Date(project.endDate).toLocaleDateString()}
      </p>
      <p>Аудитори:</p>
      <ul>
        {project.auditors.map((auditor) => (
          <li key={auditor._id}>{auditor.name} ({auditor.email})</li>
        ))}
      </ul>

      <Link to={`/projects/${projectId}/checklist`}>Переглянути чекліст</Link>

      <h3>Документи</h3>
      <DocumentUpload projectId={projectId} />

      <ul>
        {documents.map((doc) => (
          <li key={doc._id}>
            <Link to={`/documents/${doc._id}`}>{doc.type} — {new Date(doc.uploadedAt).toLocaleString()}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ProjectDetail;
