import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

function Login() {
  const { login, error } = useContext(AuthContext); // Використання error
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      if (!error) { // Перевірка відсутності помилки
        navigate('/');
      }
    } catch (error) {
      console.error('Помилка входу:', error.message);
    }
  };

  // Функція для входу через Google
  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/google`;
  };

  return (
    <div>
      <h2>Вхід</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>} {/* Виведення помилки */}
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        /><br />
        <input
          type="password"
          placeholder="Пароль"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        /><br />
        <button type="submit">Увійти</button>
      </form>
      <hr />
      <button onClick={handleGoogleLogin}>Увійти через Google</button>
      <br />
      {/* Кнопка для переходу на сторінку реєстрації */}
      <button onClick={() => navigate('/register')}>Зареєструватися</button>
    </div>
  );
}

export default Login;
