import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Checklist({ projectId }) {
  const [checklists, setChecklists] = useState([]);
  const [newItem, setNewItem] = useState('');

  // Завантаження чекліста для проекту
  useEffect(() => {
    const fetchChecklists = async () => {
      try {
        const res = await axios.get(`/api/checklists/project/${projectId}`);
        setChecklists(res.data);
      } catch (error) {
        console.error('Помилка завантаження чеклістів:', error.message);
      }
    };
    fetchChecklists();
  }, [projectId]);

  // Додавання нового пункту до чекліста
  const handleAddItem = async () => {
    if (!newItem) return;
    try {
      const res = await axios.post('/api/checklists', {
        projectId,
        content: newItem,
      });
      setChecklists([...checklists, res.data]);
      setNewItem('');
    } catch (error) {
      console.error('Помилка додавання пункту:', error.message);
    }
  };

  // Зміна статусу пункту
  const toggleItem = async (itemId) => {
    try {
      const res = await axios.put(`/api/checklists/${itemId}/toggle`);
      setChecklists(
        checklists.map((item) =>
          item._id === itemId ? { ...item, completed: res.data.completed } : item
        )
      );
    } catch (error) {
      console.error('Помилка оновлення пункту:', error.message);
    }
  };

  return (
    <div>
      <h3>Чекліст</h3>
      <ul>
        {checklists.map((item) => (
          <li key={item._id}>
            <input
              type="checkbox"
              checked={item.completed}
              onChange={() => toggleItem(item._id)}
            />
            {item.content}
          </li>
        ))}
      </ul>
      <input
        type="text"
        placeholder="Новий пункт"
        value={newItem}
        onChange={(e) => setNewItem(e.target.value)}
      />
      <button onClick={handleAddItem}>Додати</button>
    </div>
  );
}

export default Checklist;
